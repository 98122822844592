/* keyframes */

@keyframes pulse {
    0% {
        transform: scale(.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.9);
    }
}

@keyframes rotate-center {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotate-center {
    animation: rotate-center 3s ease-in-out infinite;
}