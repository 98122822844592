/* utility classes */

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-block {
    display: block;
}

.d-grid {
    display: grid;
}

.col-1 {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-1 {
    flex: 1;
}

.g-1 {
    gap: 1px;
}

.g-2 {
    gap: 2px;
}

.g-4 {
    gap: 4px;
}

.g-6 {
    gap: 6px;
}

.g-8 {
    gap: 8px;
}

.g-10 {
    gap: 10px;
}

.g-12 {
    gap: 12px;
}

.g-14 {
    gap: 14px;
}

.g-16 {
    gap: 16px;
}

.g-20 {
    gap: 20px;
}

.g-24 {
    gap: 24px;
}

.g-30 {
    gap: 30px;
}

.g-40 {
    gap: 40px;
}

.p-relative {
    position: relative;
}

.z-2 {
    z-index: 2;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-header {
    color: var(--header);
}

.text-right {
    text-align: right;
}

.text-black {
    color: #000;
}

.text-highlight {
    color: var(--highlight);
}

.text-10 {
    font-size: 0.625rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-600 {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.border-top {
    border-top: 1px solid var(--border);
}

.border-color-bottom {
    border-bottom: 4px solid var(--border);

    .card_footer--sm {
        height: 69px;
    }
}

.border-4 {
    border-radius: 4px;
}

.border-8 {
    border-radius: 8px;
}

.h-1 {
    height: calc(220px + var(--widget-scale)) !important;
}

.h-2 {
    height: calc(460px + var(--widget-scale)) !important;
}

.h-3 {
    height: calc(700px + var(--widget-scale)) !important;
}

.h-4 {
    height: calc(942px + var(--widget-scale)) !important;
}

.h-fit {
    height: fit-content !important;
}

.c-pointer {
    cursor: pointer;
}

.card-padded {
    padding: var(--card-padding);
}