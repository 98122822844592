/* global template styles */

html {
    user-select: none;
    font-size: calc(100% * var(--font-scale));

    &.no-transition *:not(.swiper:not(.selection-list) *) {
        transition: none !important;
    }

    &.no-scroll {
        overflow: hidden;
    }
}

body {
    background: var(--body);
    color: var(--text);
    font-family: var(--body-font);
    font-size: 0.875rem;
    line-height: 20px;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &_container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin: 15px 15px 70px;
    }

    &.fluid {
        display: block;

        .app_container {
            margin: 0;
        }
    }

    .layout {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    @media screen and (min-width: 768px) {
        .app_container {
            margin-bottom: 40px;
        }

        .layout {
            margin: -20px -25px;
        }
    }

    @media screen and (min-width: 1920px) {

        // Obligamos a que .app_container se igual al 90% de toda la pantalla maximo 960px
        .app_container {
            min-width: 1728px;
            width: 90%;
            margin: 30px auto;
        }

        // display: grid;
        // grid-template-columns: minmax(0, 250px) minmax(0, (calc(100% - 250px)));


        // .app_container {
        //     margin: 30px 40px 40px;
        //     grid-area: 1 / 2 / 2 / 3;
        // }
    }
}

.lazy-image {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        height: 100%;
    }
}

input, input::placeholder {
    color: var(--text);
}

// color de la imagen es blanco y negro porque aun no eres dueño
.not-owner {
    filter: grayscale(100%) !important;
    -webkit-filter: grayscale(100%) !important;
}

$primary-bg: #f9f9f9;
$card-bg: white;
$border-color: #ccc;
$button-bg: #007bff;
$button-color: white;

.book-store {
  padding: 20px;
  background-color: $primary-bg;

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center; // Centrando el título
  }

  p {
    margin-bottom: 20px;
    text-align: center; // Centrando el párrafo
  }

  .filter-bar {
    display: flex;
    flex-wrap: wrap; // Permite que los botones se ajusten a múltiples líneas
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center; // Centrando los botones

    button {
      background-color: $button-bg;
      color: $button-color;
      border: none;
      padding: 10px 20px; // Aumentando el padding horizontalmente
      border-radius: 5px;
      cursor: pointer;
      flex: 1; // Permite que los botones crezcan
      min-width: 100px; // Estableciendo un ancho mínimo para los botones

      &:hover {
        background-color: darken($button-bg, 10%);
      }
    }
  }

  .book-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;

    .book-card {
      border: 1px solid $border-color;
      padding: 10px;
      border-radius: 5px;
      background-color: $card-bg;
      text-align: center;

      img {
        max-width: 100%;
        border-radius: 5px;
      }

      h2 {
        font-size: 1.2em;
        margin: 10px 0;
        min-height: 40px; // Establecer una altura mínima para mantener la simetría
      }

      p {
        margin: 5px 0;
      }

      a {
        background-color: $button-bg;
        color: $button-color;
        border: none;
        padding: 8px 16px; // Aumentando el padding horizontalmente
        border-radius: 4px;
        cursor: pointer;
        min-width: 100px; // Estableciendo un ancho mínimo para los botones

        &:hover {
          background-color: darken($button-bg, 10%);
        }
      }
    }
  }
}

// Medias Queries para ajuste en diferentes pantallas
@media (max-width: 768px) {
  .book-store {
    padding: 15px;

    h1 {
      font-size: 1.8em;
    }

    .filter-bar {
      flex-direction: column; // Cambiando a columna en pantallas más pequeñas
      align-items: center; // Centrando los botones
    }
  }
}

@media (max-width: 480px) {
  .book-store {
    h1 {
      font-size: 1.5em;
    }
    
    p {
      font-size: 0.9em;
    }

    .book-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Ajustando columnas
    }
  }
}


$terms-bg: white;
$heading-color: #333;

.terms-and-conditions {
  padding: 20px;
  background-color: $terms-bg;

  h1 {
    font-size: 2em;
    margin-bottom: 15px;
    color: $heading-color;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: $heading-color;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: $heading-color;
  }
}
